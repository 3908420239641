// KOREA
//const backServer = "http://admin.kr.edenlx.com:8081";

// JAPAN
 //const backServer = "http://admin.jp.edenlx.com:8081";

// TAIWAN
 //const backServer = "http://admin.tw.edenlx.com:8081";

// // China
 const backServer = "http://admin.cn.edenlux.cn:8081";

//USA
 //const backServer = "http://admin.us.edenlx.com:8081";

export default backServer;